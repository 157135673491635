import {
  analysisFlags,
  controlAndSuggestionsFlags,
  operationsFlags,
  otherFlags,
  todayFlags,
  userProfileFlags,
} from './flags'

/**
 * @typedef {import('../FlagManagerProps').Section} Section
 */

/**
 * @type {Section[]}
 */
export const flagSections = [
  {
    id: 'userProfile',
    title: 'User profile',
    flags: userProfileFlags,
  },
  {
    id: 'today',
    title: 'Today',
    flags: todayFlags,
  },
  {
    id: 'controlAndSuggestions',
    title: 'Control & Suggestions',
    flags: controlAndSuggestionsFlags,
  },
  {
    id: 'operations',
    title: 'Operations',
    flags: operationsFlags,
  },
  {
    id: 'analysis',
    title: 'Analysis',
    flags: analysisFlags,
  },
  {
    id: 'other',
    title: 'Other',
    flags: otherFlags,
  },
]
