import types from 'prop-types'
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { RetryLink } from '@apollo/client/link/retry'
import { onError } from '@apollo/client/link/error'

import { useAuth0 } from '@auth0/auth0-react'
import { env } from 'env'

/**
 * @typedef {import('./ProviderProps').ApolloProviderProps} ApolloProviderProps
 */

/**
 * @param {ApolloProviderProps} props
 * @returns {React.FunctionComponentElement<any>}
 */
const AuthorizedApolloProvider = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0()

  const httpLink = createHttpLink({
    uri: env.REACT_APP_APOLLO_URI,
  })

  const authLink = setContext(async (_, { headers }) => {
    const token = await getAccessTokenSilently()

    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    }
  })

  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message }) => {
        console.error(
          `[GraphQL error]: ${message}.`,
          `Operation: "${operation.operationName}".`,
          `Variables: ${JSON.stringify(operation.variables)}.`
        )
      })
    }
    if (networkError) console.error(`[Network error]: ${networkError}`)
  })

  const retryLink = new RetryLink({
    delay: {
      initial: 300,
      max: Infinity,
      jitter: true,
    },
    attempts: {
      max: 3,
      retryIf: (error, _operation) => !!error,
    },
  })

  const apolloClient = new ApolloClient({
    link: ApolloLink.from([authLink, errorLink, retryLink, httpLink]),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        errorPolicy: 'all',
        notifyOnNetworkStatusChange: true,
      },
      query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      },
    },
    connectToDevTools: process.env.NODE_ENV === 'development',
  })

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}

AuthorizedApolloProvider.propTypes = {
  children: types.oneOfType([types.arrayOf(types.node), types.node]).isRequired,
}

AuthorizedApolloProvider.displayName = 'ApolloProvider'
export default AuthorizedApolloProvider
