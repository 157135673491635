import { Fragment } from 'react'
import types from 'prop-types'
import { useFlags } from '../hooks/useFlags'

/**
 * @param {Object} props
 * @param {React.ReactNode | React.ReactNode[]} props.children
 * @param {string} props.name
 *
 * @returns {React.FunctionComponentElement<any> | null}
 */

const Flag = ({ children, name }) => {
  const { flags } = useFlags()

  const shouldShow = flags[name]

  if (!shouldShow) {
    return null
  }
  return <Fragment>{children}</Fragment>
}

Flag.propTypes = {
  children: types.node.isRequired,
  name: types.string,
}

Flag.displayName = 'Flag'
export default Flag
