import types from 'prop-types'
import { Avatar } from '@chakra-ui/react'

/**
 * @typedef {Object} RowData
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} pictureLink
 *
 * @typedef {Object} ColDef
 * @property {string} field
 *
 * @param {Object} props
 * @param {string} props.alt
 * @param {ColDef} props.colDef
 * @param {RowData} props.data
 * @returns {React.FunctionComponentElement<any>}
 */
const AvatarCellRenderer = ({ data, colDef }) => {
  if (!data) {
    console.error(
      `* Error caught in AvatarCellRenderer. Expected data but got ${data}`
    )
    return null
  }

  return (
    <Avatar
      name={`${data.firstName} ${data.lastName}`}
      size='sm'
      src={data[colDef.field]}
    />
  )
}

AvatarCellRenderer.propTypes = {
  colDef: types.shape({
    field: types.string.isRequired,
  }),
  data: types.object,
}
AvatarCellRenderer.defaultProps = {}

export default AvatarCellRenderer
