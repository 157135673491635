import { Fragment } from 'react'
// import { useDisclosure } from '@chakra-ui/react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { useAuth } from 'shared/hooks'
import { AuthedRoute, NotFound } from './components'
import { Facility, Landing, Profile } from 'screens'

import Splash from 'components/Splash'

// XXX: Temporarily removed due to inability of conditional rendering of the UI flag manager
// const FlagManagerModal = lazy(() =>
//   import('../components/flagManager/FlagManager')
// )

const AppRouter = () => {
  // const { isOpen, onClose, onOpen } = useDisclosure()
  const { isLoading } = useAuth()

  if (isLoading) {
    return <Splash message='Loading...' />
  }

  return (
    <Fragment>
      <Switch>
        <AuthedRoute exact path='/facilities' element={Landing} />
        <AuthedRoute path='/facilities/:facilityId' element={Facility} />
        <AuthedRoute path='/profile' element={Profile} />
        <Redirect exact from='/' to='/facilities' />
        <Route component={NotFound} />
      </Switch>
      {/* <Suspense fallback={<div />}>
        <FlagManagerModal isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
      </Suspense> */}
    </Fragment>
  )
}

AppRouter.displayName = 'AppRouter'
export default AppRouter
