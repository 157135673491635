import { useContext } from 'react'
import cloneDeep from 'lodash/cloneDeep'

import { FlagManagerContext } from 'services/flagManager/FlagManager'

/**
 * @typedef {import('services/flagManager/FlagManagerProps').SectionOptions} SectionOptions
 */

/**
 * @typedef {Object.<string, boolean>} Flag
 *
 * @typedef {Object} FlagsOutput
 * @property {(flag: string) => void} addFlag
 * @property {(flag: string) => void} removeFlag
 * @property {(flag: string) => void} toggleFlag
 * @property {Flag} flags
 *
 * @param {SectionOptions | SectionOptions[]} [targetGroup] - targetGroup
 * @returns {FlagsOutput} output
 */
export const useFlags = targetGroup => {
  const context = useContext(FlagManagerContext)
  const { flags } = context

  if (targetGroup == null) {
    return context
  }

  let matchedFlags = {}
  if (typeof targetGroup === 'string') {
    matchedFlags = Object.keys(flags).reduce(
      (acc, curr) =>
        curr.startsWith(targetGroup) ? { ...acc, [curr]: flags[curr] } : acc,
      {}
    )
  } else {
    matchedFlags = Object.keys(flags).reduce(
      (acc, curr) =>
        targetGroup.some(section => curr.startsWith(section))
          ? { ...acc, [curr]: flags[curr] }
          : acc,
      {}
    )
  }

  return Object.assign(cloneDeep(context), {
    flags: matchedFlags,
  })
}
