import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Avatar,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react'
import { LogOut, User } from 'react-feather'

import { useAuth } from 'shared/hooks'

const states = {
  INITIAL: 'INITIAL',
  SWITCHING_ORGANIZATION: 'SWITCHING_ORGANIZATION',
}

// XXX: exclude Switch Organization feature from the app flow [10/26/2021]
// XXX: to be reviewed in a future iteration whether this is still needed
/**
 * @returns {React.FunctionComponentElement<any>}
 */
const UserMenu = () => {
  const [state, setCurrentState] = useState(states.INITIAL)

  const history = useHistory()
  const { logout, user } = useAuth()

  const menuItems = [
    {
      closeOnSelect: true,
      isDisabled: false,
      Icon: User,
      onClick: () => {
        history.push('/profile', { from: history.location.pathname })
      },
      label: 'Profile',
    },

    // {
    //   closeOnSelect: false,
    //   isDisabled: false,
    //   Icon: HiSwitchHorizontal,
    //   onClick: () => {
    //     setCurrentState(states.SWITCHING_ORGANIZATION)
    //   },
    //   label: 'Switch organization',
    // },
    {
      closeOnSelect: true,
      isDisabled: false,
      Icon: LogOut,
      onClick: () => {
        logout({
          returnTo: window.location.origin,
        })
        setCurrentState(states.INITIAL)
      },
      label: 'Log out',
    },
  ]

  const handleMenuClose = () => {
    if (state !== states.INITIAL) {
      setCurrentState(states.INITIAL)
    }
  }

  if (!user) {
    return null
  }

  return (
    <Menu onClose={handleMenuClose}>
      <MenuButton
        as={IconButton}
        aria-label='User Menu'
        borderRadius='round'
        height='100%'
        icon={
          <Avatar
            height={{ base: '36px', xl: '40px' }}
            name={`${user.firstName} ${user.lastName}`}
            objectFit='contain'
            src={user.pictureLink}
            width={{ base: '36px', xl: '40px' }}
          />
        }
        width='fit-content'
        variant='ghost'
        _hover={{
          outline: '1px solid',
          outlineColor: 'info.300',
          opacity: 0.75,
        }}
        _expanded={{
          outline: '1px solid',
          outlineColor: 'info.500',
          opacity: 0.75,
        }}
        _focus={{
          outline: '1px solid',
          outlineColor: 'info.400',
          opacity: 0.75,
        }}
      />
      {
        state === states.INITIAL ? (
          <MenuList>
            {menuItems.map(item => (
              <MenuItem
                closeOnSelect={item.closeOnSelect}
                isDisabled={item.isDisabled}
                icon={<item.Icon height='16px' width='16px' />}
                key={item.label}
                onClick={item.onClick}>
                {item.label}
              </MenuItem>
            ))}
          </MenuList>
        ) : null
        /* (
        <OrganizationList
          onBack={() => setCurrentState(states.INITIAL)}
          onSelect={() => setCurrentState(states.INITIAL)}
        />
      ) */
      }
    </Menu>
  )
}

UserMenu.propTypes = {}

UserMenu.displayName = 'UserMenu'
export default UserMenu
