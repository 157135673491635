import { lazy } from 'react'
import { userRoles } from 'shared/constants'
import { withSuspense } from 'shared/decorators'
import {
  AnalysisIcon,
  ControlAndSuggestionsIcon,
  OperationsIcon,
  // TodayIcon,
} from 'assets/icons'

// const TodayRouter = lazy(() => import('./today'))
const ControlAndSuggestionsRouter = lazy(() =>
  import('./controlAndSuggestions/ControlAndSuggestions')
)
const OperationsRouter = lazy(() => import('./operations/Operations'))
const AnalysisRouter = lazy(() => import('./analysis/Analysis'))

const routes = [
  // {
  //   path: 'today',
  //   name: 'Today',
  //   Component: withSuspense(TodayRouter),
  //   Icon: TodayIcon,
  //   id: 'today-router',
  //   requiredRoles: [],
  //   childRoutes: [
  //     {
  //       name: 'Overview',
  //       path: 'overview',
  //     },
  //     {
  //       name: 'Energy',
  //       path: 'energy',
  //     },
  //     {
  //       name: 'Refrigerations',
  //       path: 'refrigerations',
  //     },
  //     {
  //       name: 'Operations',
  //       path: 'operations',
  //     },
  //   ],
  // },
  {
    path: 'control-and-suggestions',
    name: 'Control & Suggestions',
    Component: withSuspense(ControlAndSuggestionsRouter),
    Icon: ControlAndSuggestionsIcon,
    id: 'control-and-suggestions-router',
    requiredRoles: [userRoles.CONTXT_CONTROL_USER],
    childRoutes: [
      {
        name: 'Active projects',
        path: 'active-projects',
      },
      {
        name: 'Applicable projects',
        path: 'applicable-projects',
      },
    ],
  },
  {
    path: 'operations',
    name: 'Operations',
    Component: withSuspense(OperationsRouter),
    Icon: OperationsIcon,
    id: 'operations-router',
    requiredRoles: [userRoles.BLAST_USER, userRoles.WMS_USER],
    childRoutes: [
      {
        name: 'Scheduling',
        path: 'scheduling',
      },
      {
        name: 'Cell Status',
        path: 'cell-status',
      },
    ],
  },
  {
    path: 'analysis',
    name: 'Analysis',
    Component: withSuspense(AnalysisRouter),
    Icon: AnalysisIcon,
    id: 'analysis-router',
    requiredRoles: [userRoles.BLAST_USER],
    childRoutes: [
      {
        name: 'Cycles',
        path: 'cycles',
      },
      {
        name: 'Products',
        path: 'products',
      },
    ],
  },
]

export default routes
