import types from 'prop-types'
import { useHistory } from 'react-router-dom'
import { Auth0Provider } from '@auth0/auth0-react'
import { env } from 'env'

const AUTH0_CONFIG = JSON.parse(env.REACT_APP_AUTH0)
/**
 * @typedef {import('./ProviderProps').Auth0ProviderProps} Auth0ProviderProps
 */

/** @readonly */
const auth0Config = Object.freeze({
  audience: AUTH0_CONFIG.AUDIENCE,
  clientId: AUTH0_CONFIG.CLIENT_ID,
  domain: AUTH0_CONFIG.DOMAIN,
  redirectUri: window.location.origin,
})

/**
 * @param {Auth0ProviderProps} props
 * @returns {React.FunctionComponentElement<any>}
 */
const Auth0ProviderWithHistory = ({ children }) => {
  const history = useHistory()

  /**
   * @param {Object} appState
   * @param {string} appState.returnTo
   */
  const onRedirectCallback = appState => {
    history.replace(appState?.returnTo ?? window.location.pathname)
  }

  return (
    <Auth0Provider
      useRefreshTokens
      audience={auth0Config.audience}
      cacheLocation='localstorage'
      domain={auth0Config.domain}
      clientId={auth0Config.clientId}
      redirectUri={auth0Config.redirectUri}
      onRedirectCallback={onRedirectCallback}>
      {children}
    </Auth0Provider>
  )
}

Auth0ProviderWithHistory.propTypes = {
  children: types.oneOfType([types.arrayOf(types.node), types.node]).isRequired,
}

Auth0ProviderWithHistory.displayName = 'Auth0Provider'
export default Auth0ProviderWithHistory
