import { extendTheme } from '@chakra-ui/react'
import { breakpoints } from './breakpoints'
import { colors } from './colors'
import { config } from './config'
import { fonts } from './fonts'
import { globalStyle } from './global'
import { radii } from './radii'
import { shadows } from './shadows'
import { Badge, Button, Input, Menu, Table, Tabs } from './components'
import { cleanupTheme } from './utils'

/**
 * @typedef {import('@chakra-ui/react').ThemeOverride} ThemeOverride
 */

/**
 * @type {ThemeOverride}
 */
const customTheme = extendTheme({
  breakpoints,
  colors,
  components: {
    Badge,
    Button,
    Input,
    Menu,
    Table,
    Tabs,
  },
  config,
  fonts,
  radii,
  shadows,
  styles: {
    global: globalStyle,
  },
  header: {
    height: '84px',
  },
  mainContainer: {
    paddingLeft: '24px',
    paddingTop: '32px',
  },
  sidePanel: {
    width: '416px',
  },
})

/**
 * @type {ThemeOverride}
 */
const finalTheme = cleanupTheme(customTheme)

if (process.env.NODE_ENV === 'development') {
  // add theme object to window for easy debugging
  global.theme = finalTheme
}

export default finalTheme
export { themeGet } from './utils'
