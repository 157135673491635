import { createContext, useEffect, useState } from 'react'
import types from 'prop-types'
import omit from 'lodash/omit'

import { flagSections } from './definitions/sections'

/**
 * @typedef {import('./FlagManagerProps').FlagManagerProvider} FlagManagerProviderProps
 */

const flagDefinitions = flagSections.reduce(
  (acc, section) => ({
    ...acc,
    ...section.flags.reduce(
      (flags, flag) => ({
        ...flags,
        [`${section.id}__${flag.key}`]: flag.isDefaultChecked ?? false,
      }),
      {}
    ),
  }),
  {}
)

export const FlagManagerContext = createContext({
  addFlag: /** @param {string} flag */ flag => {},
  removeFlag: /** @param {string} flag */ flag => {},
  toggleFlag: /** @param {string} flag */ flag => {},
  flags: flagDefinitions,
})

/**
 * @param {FlagManagerProviderProps} props
 * @returns
 */
export const FlagManagerProvider = ({ children }) => {
  const [flags, storeFlags] = useState(flagDefinitions)

  useEffect(() => {
    const existingFlags = localStorage.getItem('flags')
    if (existingFlags) {
      const normalisedFlags = normaliseExistingFlags(existingFlags)
      storeFlags(normalisedFlags)
      localStorage.setItem('flags', JSON.stringify(normalisedFlags))
    } else {
      localStorage.setItem('flags', JSON.stringify(flagDefinitions))
    }

    function normaliseExistingFlags(existingFlags) {
      const parsedFlags = JSON.parse(existingFlags)
      const currentFlags = Object.keys(flagDefinitions).reduce(
        (acc, flag) => ({
          ...acc,
          [flag]: parsedFlags[flag] ?? flagDefinitions[flag],
        }),
        {}
      )
      return currentFlags
    }
  }, [])

  /**
   * @param {string} flag
   */
  function addFlag(flag) {
    const updatedFlags = {
      ...flags,
      [flag]: true,
    }
    updateStoredFlags(updatedFlags)
  }

  /**
   * @param {string} flag
   */
  function removeFlag(flag) {
    const updatedFlags = omit(flags, flag)
    updateStoredFlags(updatedFlags)
  }

  /**
   * @param {string} flag
   */
  function toggleFlag(flag) {
    const updatedFlags = {
      ...flags,
      [flag]: !flags[flag],
    }
    updateStoredFlags(updatedFlags)
  }

  /**
   * @param {Object} newFlags
   */
  function updateStoredFlags(newFlags) {
    storeFlags(newFlags)
    localStorage.setItem('flags', JSON.stringify(newFlags))
  }

  return (
    <FlagManagerContext.Provider
      value={{
        addFlag,
        flags,
        removeFlag,
        toggleFlag,
      }}>
      {children}
    </FlagManagerContext.Provider>
  )
}

FlagManagerProvider.propTypes = {
  children: types.node.isRequired,
}
