/**
 * @typedef {import('../FlagManagerProps').GenericFlag} GenericFlag
 */

/** @type {GenericFlag[]} flags */
export const userProfileFlags = []

/** @type {GenericFlag[]} flags */
export const todayFlags = []

/** @type {GenericFlag[]} flags */
export const controlAndSuggestionsFlags = []

/** @type {GenericFlag[]} flags */
export const operationsFlags = []

/** @type {GenericFlag[]} flags */
export const analysisFlags = []

/** @type {GenericFlag[]} flags */
export const otherFlags = [
  {
    isDefaultChecked: false,
    key: 'showNotificationsButton',
    label: 'Show notifications button',
  },
]
