import get from 'lodash/get'

/**
 * @typedef {import('@chakra-ui/react').ThemeOverride} ThemeOverride
 */

/**
 * @param {ThemeOverride} theme
 * @returns {ThemeOverride}
 */
export function expand(theme) {
  const keys = Object.keys(theme)
  for (let i = 0; i < keys.length; ++i) {
    const key = keys[i]
    const subkeys = key.split(/,\s?/)
    const target = theme[key]
    delete theme[key]
    subkeys.forEach(function (key) {
      theme[key] = target
    })
  }
  return theme
}

/**
 * @param {ThemeOverride} theme
 * @returns {ThemeOverride}
 */
export function cleanupTheme(theme) {
  // Temporary solution to cleanup color palettes;
  // https://github.com/chakra-ui/chakra-ui/issues/2652#issuecomment-736476869
  const arrayOfKeysToRemove = [
    'facebook',
    'linkedin',
    'messenger',
    'telegram',
    'twitter',
    'whatsapp',
  ]

  arrayOfKeysToRemove.forEach(key => delete theme.colors[key])

  return theme
}

/**
 *
 * @param {string} path
 * @param {string} fallback
 * @returns {collectThemeValue}
 */
export const themeGet = (path, fallback = null) => {
  return props => collectThemeValue(props)

  /**
   * @param {Object} props - component props
   * @param {ThemeOverride} props.theme - app theme
   * @returns {string | null}
   */
  function collectThemeValue(props) {
    const value = get(props.theme, path, fallback)

    if (!value) {
      console.error(`* Failed to find property '${path}'. Style not applied.`)
      return null
    }
    return value
  }
}
